var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-table", {
    ref: "table",
    attrs: {
      hideBottom: "",
      isTitle: false,
      usePaging: false,
      title: `${_vm.props.row.resultType} 참가자 목록`,
      columns: _vm.grid.columns,
      data: _vm.props.row.users,
      gridHeight: _vm.grid.height,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }